import React, { Component, createRef, RefObject } from 'react';
import ReactDOM from 'react-dom';
import {
    Icon,
    FocusTrapZone,
} from '@fluentui/react';
import { String } from 'typescript-string-operations';
import { globalPaths } from 'utils/dataClient';
import styles from './ProactiveChatModal.module.scss';
import generalStyles from 'styles/generalStyles.module.scss';

interface IProactiveChatModal {
    cancelOnClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent> | null) => void;
    chatNowOnClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent> | null) => void;
    chatMarketImage: string;
    needHelp: string;
    sectionText: string;
    chatModalAria: string;
    chatNow: string;
    chatNowAria: string;
    noThanks: string;
    noThanksAria: string;
    modalCloseButton: string;
    isRTLCulture: boolean;
}

export default class ProactiveChatModalComponent extends Component<IProactiveChatModal, {}> {
    private el: any;
    private modalRoot: any;
    private node: RefObject<HTMLDivElement>;

    constructor(props: IProactiveChatModal) {
        super(props);
        this.el = document.createElement('div');
        this.modalRoot = document.getElementById('modal-root');
        this.node = createRef();
    }

    componentDidMount() {
        this.modalRoot.appendChild(this.el);
        if (this.node && this.node.current) {
            this.node.current.focus({
                preventScroll: true,
            });
        }
    }

    componentWillUnmount() {
        this.modalRoot.removeChild(this.el);
    }

    keydown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (
            event.key === 'Escape' ||
            event.key === 'ArrowLeft' ||
            event.key === 'ArrowRight'
        ) {
            this.props.cancelOnClick(null);
        }
    };

    private backgroundImageUrlGen = (chatMarketImage: string) => {

        let fileName = window.innerWidth >= 768 ? "chat_popup_main.png" : "chat_popup_main_mobile.png"

        return String.Format("{0}/assets/liveperson/{1}/{2}", globalPaths.baseUrl, chatMarketImage, fileName);
    }

    renderContainer = () => {
        const {
            chatMarketImage,
            chatNowOnClick,
            cancelOnClick,
            needHelp,
            sectionText,
            chatModalAria,
            chatNow,
            chatNowAria,
            noThanks,
            noThanksAria,
            modalCloseButton,
            isRTLCulture
        } = this.props;

        let proactiveStyle = {
            backgroundImage: `url(${this.backgroundImageUrlGen(chatMarketImage)})`
        }


        return (
            <FocusTrapZone>
                <div
                    className={`${styles.modalWrapper}`}
                >
                    <div id="lp-proactive-chat" className={`${styles.lp_proactive_chat} ${styles.content}`} tabIndex={0} onKeyDown={this.keydown} style={proactiveStyle} aria-live="polite" aria-label={chatModalAria} ref={this.node}>
                        <div>
                            <a
                                className={`${styles.modalClose}`}
                                aria-label={modalCloseButton}
                                onClick={cancelOnClick}
                            >
                                x
                            </a>
                        </div>
                        <div className={`column ${styles.lp_proactive_chat_body}`}>
                            <div className="row">
                                <h3 className={`${styles.text_heading3}`}>{needHelp}</h3>
                                <p>{sectionText}</p>
                            </div>
                        </div>
                        <div className={`${styles.lp_proactive_chat_buttons}`}>
                            <a id="chatEngagement" href="#" target="_blank" className={`${styles.chatEngagement}`} onClick={chatNowOnClick} role="button" aria-label={chatNowAria}>
                                <span>
                                    {chatNow}
                                    <Icon
                                        iconName={
                                            isRTLCulture ? 'ChevronLeftSmall' : 'ChevronRightSmall'
                                        }
                                        className={`${generalStyles.ChevronStyle}`}
                                    ></Icon>
                                </span>

                            </a>
                            <a id="lp-chatnow-cancel-button" href="#" className={`${styles.lp_chatnow_cancel_button}`} onClick={cancelOnClick} role="button" aria-label={noThanksAria}>
                                <span className={`${styles.cancelSpan}`}>
                                    {noThanks}
                                    <Icon
                                        iconName={
                                            isRTLCulture ? 'ChevronLeftSmall' : 'ChevronRightSmall'
                                        }
                                        className={`${generalStyles.ChevronStyle}`}
                                    ></Icon>
                                </span>

                            </a>
                        </div>
                    </div>
                </div>
            </FocusTrapZone>
        );
    };

    render() {
        return ReactDOM.createPortal(this.renderContainer(), this.el);
    }
}
