import React, { useEffect, useState } from 'react';
import { IOldTourStateWithDispatch } from 'types/tour.type';
import { IAppState } from 'types/appState.type';
import PopupComponentContainer from 'components/popup/PopupComponentContainer';
import StepItemContainer from 'components/shortComponents/stepItem/StepItemContainer';
import { GetCallbackUrl, GetCallbackText } from 'utils/queryUtils';
import ProjectComponentContainer from 'components/project/ProjectComponentContainer';
import { useTranslation } from 'react-i18next';
import { projectNamespace } from 'i18n';
import CollectionLoadingComponentContainer from 'components/collectionLoading/CollectionLoadingComponentContainer';
import LocaleComponentContainer from 'components/locale/LocaleComponentContainer';
import { TourLoadingComponent } from 'components/tourLoading/TourLoadingComponent';
import { ImageComponent } from 'components/shortComponents/image/ImageComponent';
import { Image, Tour } from 'types/schema.type';
import  ContactRightRailComponentContainer  from 'components/common/ContactRightRailComponentContainer';
import ScenarioRedirect, {
    ShouldRedirect,
} from 'components/shortComponents/scenarioRedirect/ScenarioRedirect';
 
import styles from './DemoComponent.module.scss';
import { globalPaths } from '../../utils/dataClient';
import {
    getNativeLongFormState,
    bulkLoadImagesForTour,
    fixTourTitle,
} from 'utils/renderHelpers';
 
export const DemoComponent = (props: IOldTourStateWithDispatch) => {
    const {
        projectState,
        tourState,
        stepState,
        localeState,
        sectionState,
        collectionState,
        match,
        router,
        setTourRunning,
    } = props;
 
    const { sectionId, stepId } = match.params;
 
    const collectionId = match.params.collectionId
        ? match.params.collectionId.toLowerCase()
        : '';
    const selectedLocale = match.params.collectionId
        ? match.params.selectedLocale.toLowerCase()
        : '';
    const tourId = match.params.tourId ? match.params.tourId.toLowerCase() : '';
 
    const { isLoading, isTourRunning } = tourState;
    const { location } = router;
    const { project, projectLoaded, isLanding } = projectState;
    const { selectedStep } = stepState;
    const { selectedSection } = sectionState;
 
    const { selectedCollection } = collectionState;
 
    const isNativeLongForm = getNativeLongFormState(props);
    const selectedTour = tourState.selectedTour
        ? tourState.selectedTour
        : new Tour();
 
    const namespaceString = isNativeLongForm ? 'tours' : 'scenarios';
    let nameSpaces = [projectNamespace];
 
    const { localeHexCode, isRTLCulture, fallbackLocale } = localeState;
 
    const [callback, setcallback] = useState(false);
    const DynamicsCollectionID = 'dynamics';
 
    let hasSelectedTour: boolean = false;
    if (selectedTour && selectedTour.id) {
        nameSpaces.push(`${namespaceString}/${selectedTour.id}`);
        hasSelectedTour = true;
    }
 
    const { t } = useTranslation(nameSpaces);
    const defaultTourTitle = t('scenarioTitleSuffix');
    const tourTitle = hasSelectedTour
        ? t(`${namespaceString}/${selectedTour.id}:${selectedTour.title}`)
        : defaultTourTitle;
 
        const [useGif, setUseGif] = useState(false);
        const [imageError, setImageError] = useState(false);
        const [imageExtension, setImageExtension] = useState('png')
        const [imageSource, setImageSource] = useState('');
        const [attemptedFormats, setAttemptedFormats] = useState({});
 
        type AttemptedFormats = {
            [key: string]: boolean;
        };
 
        const handleImageError = () => {
            console.log(`Error loading image with format ${imageExtension}. Attempting to switch format.`);
       
            // Update the attempted formats state with the new attempt
            const newAttemptedFormats: AttemptedFormats = { ...attemptedFormats, [imageExtension]: true };
            setAttemptedFormats(newAttemptedFormats);
       
            // Determine the new format based on what's been attempted
            if (!newAttemptedFormats['png']) {
                setImageExtension('png');
            } else if (!newAttemptedFormats['gif']) {
                setImageExtension('gif');
            } else {
                // Both formats have been attempted; handle accordingly
                console.error('Both PNG and GIF formats failed to load.');
                // Optionally, set a default/fallback image or take other action
            }
        };          
 
        const getImageSource = (): string => {
            const namespaceId = selectedTour.id.toLowerCase().replace(/\s+/g, '-');
            const sectionNumber = selectedSection.sectionNumber;
            const stepNumber = selectedStep.stepNumber;
            const basePath = `${globalPaths.baseUrl}/${namespaceString}/${namespaceId}`;
       
            if (isNativeLongForm) {
                return `${basePath}/${sectionNumber}_${stepNumber}.${imageExtension}`;
            } else if (selectedTour.useLocaleImages) {
                return `${basePath}/${fallbackLocale}/${sectionNumber}.${stepNumber}.${imageExtension}`;
            } else {
                return `${basePath}/${sectionNumber}.${stepNumber}.${imageExtension}`;
            }
        };
 
        useEffect(() => {
            // Reset the attempted formats whenever the step changes
            setAttemptedFormats({});
            // Ensure we start with a default format (e.g., png)
            setImageExtension('png');
        }, [selectedStep]);
 
    useEffect(() => {
        if (isLanding) {
            props.setIsLanding(false);
        }
    }, [isLanding, props]);
 
    useEffect(() => {
        if (!isTourRunning) {
            setTourRunning(true);
        }
 
        return () => {
            setTourRunning(false);
        };
    }, []);
 
 
    useEffect(() => {
        if (
            localeState.selectedLocale &&
            projectLoaded &&
            selectedCollection &&
            selectedTour
        ) {
            let urlSectionId = parseInt(sectionId);
            let urlStepId = parseInt(stepId);
 
            if (!isLoading && selectedTour) {
                if (
                    urlSectionId !== undefined &&
                    urlSectionId !== null &&
                    selectedSection &&
                    urlSectionId !== selectedSection.sectionNumber &&
                    selectedTour.sections[urlSectionId - 1]
                ) {
                    props.setSection(selectedTour.sections[urlSectionId - 1]);
                }
 
                if (
                    urlStepId !== undefined &&
                    urlStepId !== null &&
                    urlStepId !== selectedStep.stepNumber &&
                    selectedTour.sections[urlSectionId - 1] &&
                    selectedTour.sections[urlSectionId - 1].steps[urlStepId - 1]
                ) {
                    props.setStep(
                        selectedTour.sections[urlSectionId - 1].steps[urlStepId - 1]
                    );
                }
            }
        }
    }, [
        props,
        projectLoaded,
        isLoading,
        selectedTour,
        localeState.selectedLocale,
        selectedCollection,
        sectionId,
        stepId,
        selectedSection,
        selectedStep.stepNumber,
    ]);
 
    useEffect(() => {
        if (
            localeState.selectedLocale &&
            !localeState.isLoading &&
            projectLoaded &&
            selectedCollection &&
            selectedTour &&
            callback
        ) {
            if (location) {
                const callback = GetCallbackUrl(location, localeHexCode);
                const callbackText = GetCallbackText(location);
 
                if (callback) {
                    props.setTourCallback(callback, callbackText);
                }
            }
        }
    }, [
        callback,
        localeHexCode,
        localeState.isLoading,
        localeState.selectedLocale,
        location,
        projectLoaded,
        props,
        selectedCollection,
        selectedTour,
    ]);
 
    if (ShouldRedirect(tourId)) {
        return (
            <ScenarioRedirect
                query={document.location.search}
                selectedLocale={selectedLocale}
                collectionId={collectionId}
                scenarioId={tourId}
                isDemoEnd={false}
                taskId={sectionId}
                stepId={stepId}
            />
        );
    }
 
    if (!localeState.selectedLocale) {
        return (
            <LocaleComponentContainer
                locale={navigator.language}
            ></LocaleComponentContainer>
        );
    }
 
    if (localeState.isLoading) {
        return <></>;
    }
 
    if (!projectLoaded) {
        return (
            <>
                <ProjectComponentContainer
                    locale={selectedLocale}
                ></ProjectComponentContainer>
            </>
        );
    }
 
    if (
        !selectedCollection ||
        selectedCollection.id !== collectionId ||
        !selectedCollection.loaded
    ) {
        return (
            <>
                <CollectionLoadingComponentContainer
                    collectionId={collectionId}
                    locale={selectedLocale}
                    isNativeLongForm={isNativeLongForm}
                    isBatchLoading={false}
                ></CollectionLoadingComponentContainer>
            </>
        );
    }
 
    if (!selectedTour || selectedTour.id !== tourId) {
        return (
            <>
                <TourLoadingComponent
                    tourId={tourId}
                    tourState={tourState}
                    isNativeLongForm={isNativeLongForm}
                    set={true}
                    loadTour={() => {
                        props.loadTour(tourId, isNativeLongForm, localeHexCode);
                    }}
                    setTour={(scenarioId: string) => {
                        props.setTour(tourId);
                    }}
                    localeHexCode={localeHexCode}
                ></TourLoadingComponent>
            </>
        );
    }
 
    if (location && localeHexCode !== '') {
        const locaCallback = GetCallbackUrl(location, localeHexCode);
        const localCallbackText = GetCallbackText(location);
 
        if (locaCallback && localCallbackText && !callback) {
            setcallback(true);
        }
    }
 
    //Set the window title to the Tour title.
    document.title = tourTitle;
 
    let urlTaskId = parseInt(sectionId);
    let urlStepId = parseInt(stepId);
 
    if (!isLoading && selectedTour) {
        if (
            urlTaskId !== undefined &&
            urlTaskId !== null &&
            sectionState.selectedSection &&
            urlTaskId !== sectionState.selectedSection.sectionNumber &&
            selectedTour.sections[urlTaskId - 1]
        ) {
            return <></>;
        }
 
        if (
            urlStepId !== undefined &&
            urlStepId !== null &&
            urlStepId !== selectedStep.stepNumber &&
            selectedTour.sections[urlTaskId - 1] &&
            selectedTour.sections[urlTaskId - 1].steps[urlStepId - 1]
        ) {
            return <></>;
        }
    }
 
    const landMarkLabel = t(project.sectionLandmarkLabel);
 
    let image: Image = new Image();
    image.path = getImageSource();
    image.alt = t(
        `${namespaceString}/${selectedTour.id}:${sectionState.selectedSection.sectionName}`
    );
 
    setTimeout(() => {
        //If we haven't bulk downloaded tour images, do so and then set flag on tour.
        if (!selectedTour.imagesLoaded) {
            if (bulkLoadImagesForTour(props, fixTourTitle(tourTitle))) {
                props.setTourImagesLoaded(selectedTour.id);
            }
        }
    }, 1);
 
    return (
        <div
            className={`${styles.page}`}
            role="main"
            aria-label={landMarkLabel}
            dir={isRTLCulture ? 'rtl' : 'ltr'}
            lang={fallbackLocale}
        >
            {selectedCollection.id === DynamicsCollectionID && (
                <ContactRightRailComponentContainer {...(props as IAppState)} />
            )}
            <div className={`${styles.pageContent}`}>
                <StepItemContainer />
                <div className={`${styles.fadeIn}`}>
                <ImageComponent
                    {...(props as IAppState)}
                    image={{ alt: "Descriptive alt text", path: getImageSource() }}
                    hideDot={false}
                    onError={() => setImageExtension('gif')}
                />
                </div>
            </div>
            <PopupComponentContainer />
        </div>
    );
};