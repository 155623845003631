import { connect } from 'react-redux';
import { IAppState } from 'types/appState.type';
import { setShowProactiveChat, setShowCallToAction } from 'actions/project.action';
import { withRouter } from 'react-router-dom';
import { ContactRightRailComponent, IContactRailProps } from './ContactRightRailComponent';

const mapStateToProps = (state: IAppState) => ({ ...state });

const mapDispatchToProps = (dispatch: any): IContactRailProps => {
	return {
		setShowCallToAction: (showCallToAction: boolean) => {
			dispatch(setShowCallToAction(showCallToAction));
		},
		setShowProactiveChat: (showProactiveChat: boolean) => {
			dispatch(setShowProactiveChat(showProactiveChat));
		},
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ContactRightRailComponent)
);
