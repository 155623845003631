import React, { useRef } from 'react';
//import { css, StyleSheet } from 'aphrodite/no-important';
import { getTheme } from '@fluentui/react/lib/Styling';
import { Link } from 'react-router-dom';
import { appInsights } from 'store/appStore';
import { IAppState } from 'types/appState.type';
import { IFocusElement } from 'types/project.type';
import { GetJsllIdPrefix } from 'utils/jsllTaggingUtils';
import { Tour } from 'types/schema.type';
import { useTranslation } from 'react-i18next';

import styles from './StepIndicator.module.scss';

export interface IStepIndicatorProps {
	setShowCallToAction(showCallToAction: boolean): void;
	setFocusElementAction(focusElementAction: IFocusElement): void;
}
export interface IStepIndicatorWithDispatch
	extends IStepIndicatorProps,
		IAppState {}

export const StepIndicator = (props: IStepIndicatorWithDispatch) => {
	const {
		stepState,
		projectState,
		localeState,
		collectionState,
		tourState,
		sectionState,
	} = props;
	const { selectedLocale, isRTLCulture } = localeState;
	const { selectedCollection } = collectionState;
	const { focusElement, project } = projectState;
	const { selectedTourId } = tourState;

	const selectedTour = tourState.selectedTour
		? tourState.selectedTour
		: new Tour();
	const { t } = useTranslation([`scenarios/${selectedTour.id}`]);

	const { selectedSection } = sectionState;
	const { selectedStep } = stepState;
	const selectedSectionId = selectedSection.sectionNumber;
	const selectedStepId = selectedStep.stepNumber;

	const { coachmarkCoordinates } = selectedStep;
	const ref: React.MutableRefObject<any> = useRef(null);

	if (!selectedCollection) {
		return <></>;
	}

	const theme = getTheme();

	let topPercentage =
		coachmarkCoordinates[1] == -1 ? 95 : coachmarkCoordinates[1];
	let leftPercentage =
		coachmarkCoordinates[0] == -1 ? 95 : coachmarkCoordinates[0];
	let rightPercentage =
		coachmarkCoordinates[0] == -1 ? -95 : -1 * (98 - coachmarkCoordinates[0]);

	const coachmarkStyle = {
		left: leftPercentage + '%',
		top: topPercentage + '%',
	};

	const coachmarkStyleRTL = {
		left: rightPercentage + '%',
		top: topPercentage + '%',		
	};

	//const styles = StyleSheet.create({
	//    stepIndicatorContainer: {
	//        width: '100%',
	//        height: '100%',
	//        pointerEvents: 'none',
	//        top: 0,
	//        left: 0,
	//        position: 'absolute',
	//    },
	//    stepIndication: {
	//        pointerEvents: 'all',
	//        display: 'block',
	//        position: 'relative',
	//        top: `${topPercentage}%`,
	//        left: `${leftPercentage}%`,
	//        width: '35px',
	//        height: '35px',
	//        transform: 'translate(-17.5px, -17.5px)',
	//        backgroundColor: theme.palette.themePrimary,
	//        borderRadius: '100%',
	//        cursor: 'pointer',
	//        ':before': {
	//            border: '15px solid',
	//            borderColor: theme.palette.themePrimary,
	//            content: '""',
	//            width: '100%',
	//            display: 'block',
	//            position: 'absolute',
	//            height: 'auto',
	//            top: '0',
	//            right: '0',
	//            bottom: '0',
	//            left: '0',
	//            borderRadius: '100%',
	//            transformOrigin: 'center center',
	//            boxSizing: 'border-box',
	//            animationName: [pulse2],
	//            animationDuration: '1s, 1s',
	//            animationIterationCount: 'infinite',
	//        },
	//        ':after': {
	//            border: '10px solid',
	//            borderColor: theme.palette.themePrimary,
	//            content: '""',
	//            width: '100%',
	//            display: 'block',
	//            position: 'absolute',
	//            height: 'auto',
	//            top: '0',
	//            right: '0',
	//            bottom: '0',
	//            left: '0',
	//            borderRadius: '100%',
	//            transformOrigin: 'center center',
	//            boxSizing: 'border-box',
	//            animationName: [pulse1],
	//            animationDuration: '1s',
	//            animationIterationCount: 'infinite',
	//        },
	//    },
	//    next: {
	//        position: 'absolute',
	//        cursor: 'pointer',
	//        right: '5px',
	//        bottom: '7px',
	//    },
	//});

	const totalSections = selectedTour.sections.length;
	const totalSteps = selectedSection.steps.length;
	const nextStepId = selectedStepId + 1;
	const nextSectionId = selectedSectionId + 1;
	const isLastStep = nextStepId > totalSteps;
	const isLastSection = nextSectionId > totalSections;

	const linkSectionId =
		isLastStep && !isLastSection ? nextSectionId : selectedSectionId;

	const linkStepId = isLastStep
		? isLastSection
			? selectedStepId
			: 1
		: nextStepId;

	if (ref) {
		try {
			(ref.current! as HTMLElement)!.focus({
				preventScroll: true,
			});
		} catch (error) {}
	}

	setTimeout(() => {
		if (focusElement === IFocusElement.Bubble && ref.current) {
			ref.current.focus();
		}
	}, 1);

	function trigger() {
		appInsights.trackEvent({
			name: 'IndicatorClick',
		});
		if (isLastStep && isLastSection) {
			props.setShowCallToAction(true);
		}
	}

	const jsllIdPrefix = GetJsllIdPrefix(props, t);

	const indicatorId = `${jsllIdPrefix}-bluedot`;
	return topPercentage > 0 && leftPercentage > 0 ? (
		<div className={`${styles.stepIndicatorContainer}`}>
			<Link
				id={indicatorId}
				data-bi-name={indicatorId}
				className={`${styles.stepIndication}`}
				style={isRTLCulture ? coachmarkStyleRTL : coachmarkStyle}
				ref={ref}
				onClick={trigger}
				onKeyPress={(e) => {
					if (e.key === 'Enter' || e.key === ' ') {
						trigger();
					}
				}}
				aria-label={project.stepNavLabelNext}
				to={`/${selectedLocale}/guidedtour/${
					selectedCollection!.id
				}/${selectedTourId}/${linkSectionId}/${linkStepId}`}
			></Link>
		</div>
	) : null;
};
